import React, { useState, useEffect } from "react";
import debounce from "lodash.debounce";
import Layout from "../components/Layout";
import SearchForm from "../components/SearchForm";
import SearchResults from "../components/SearchResults";
import { graphql } from "gatsby";
import "url-search-params-polyfill";
import { Helmet } from "react-helmet";

const Search = ({ data, location }) => {
  const [results, setResults] = useState([]);
  const searchQuery =
    new URLSearchParams(location.search).get("keywords") || "";

  useEffect(() => {
    if (searchQuery && window.__LUNR__) {
      (async () => {
        const lunr = await window.__LUNR__.__loaded;
        const match = lunr.en.index.search(searchQuery, searchQuery + "*");
        const wildcard = lunr.en.index.search("*" + searchQuery + "*");
        const refs = match.length !== 0 ? match : wildcard;
        const posts = refs.map(({ ref }) => lunr.en.store[ref]);

        setResults(posts);
      })();
    }

    if (!searchQuery) setResults([]);
  }, [location.search]);

  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
      <Helmet titleTemplate="%s | Pathlab">
        <title>{`Search`}</title>
        <meta
          name="description"
          content={`Search the Pathlab website to find the information you need.`}
        />
      </Helmet>
      <section className="section">
        <div className="container content-border">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="content browser-ie">
                <p>Your browser is lacking modern features.</p>
                <p>
                  For a modern search experience, please use Firefox, Chrome or
                  Edge to view this page.
                </p>
                <a
                  href="https://www.google.com/search?q=site%3Apathlab.co.nz"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="has-text-primary"
                >
                  <strong>
                    Alternatively, perform a Google Search for content from our
                    site.
                  </strong>
                </a>
              </div>
              <div className="content browser-modern">
                <SearchForm query={searchQuery} />
                <br />
                <SearchResults query={searchQuery} results={results} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Search;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
