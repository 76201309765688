import React from "react"
import Highlighter from "react-highlight-words"

const SearchResults = ({ query, results }) => (
  <section aria-label="Search results for all posts">
    {results.length === 0 ? (
      <h2
      className="search-results-count"
      id="search-results-count"
      aria-live="assertive"
     >
      No results for "{query}"
    </h2>
    ):<></>}
    {!!results.length && query && (
      <h2
        className="search-results-count"
        id="search-results-count"
        aria-live="assertive"
      >
        Found {results.length} results for "{query}"
      </h2>
    )}
    {!!results.length && (
      <ol className="search-results-list">
        {results.map(({ title, url, date, description }) => (
          <li key={title} className="search-result">
            <h3 className="search-results-list__heading">
              <a href={url + "?keywords=" + query} className="search-results-list__link has-text-primary">
                <Highlighter
                  searchWords={query.split(" ")}
                  autoEscape={true}
                  textToHighlight={title}
                  highlightStyle={{
                    backgroundColor: "#009fb2",
                    color: "white"
                  }}
                />
              </a>
            </h3>
            {(date) ? <small>{new Date(date).toLocaleString("en-GB")}</small> : <></>}
            {description && (
              <p>
                <Highlighter
                  searchWords={query.split(" ")}
                  autoEscape={true}
                  textToHighlight={description}
                  highlightStyle={{
                    backgroundColor: "#009fb2",
                    color: "white"
                  }}
                />
              </p>
            )}
          </li>
        ))}
      </ol>
    )}
  </section>
)

export default SearchResults
